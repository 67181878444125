/* eslint-disable no-unused-vars */
import React, { forwardRef, useState, useCallback, useEffect } from "react";
// import PropTypes from "prop-types";
//containers:
import Container from "../Container";
import lang from "../../classes/lang";
import Axios from "../../classes/axios";
import Toast from "../../classes/toast";
import { onResult, closeModal } from "../../classes/utills";

import Input from "../../components/input/Input";
import Loader from "../../components/loader/Loader";
import Calendar from "../../components/calendar/Calendar";
import dateFormat from "dateformat";

// eslint-disable-next-line no-unused-vars
const VipRequest = forwardRef((props, ref) => {
	VipRequest.propTypes = {
		// uid: PropTypes.any.isRequired,
	};
	const [loading, setLoading] = useState(true);
	const [telegramId, setTelegramId] = useState("");
	const [info, setInfo] = useState({ wagerUsd: 0, wagerUsdMin: 0, status: "none" });
	const [birthday, setBirthday] = useState("");
	const [comment, setComment] = useState("");
	const disabled = loading || info.wagerUsd < info.wagerUsdMin || telegramId.length < 1;

	const onStatus = useCallback(async () => {
		const result = await Axios.post("/api/player/vipRequestInfo", {});
		const o = await onResult(result, "unexpected_error");
		setInfo(o);
		setLoading(false);
		return o;
	}, []);

	useEffect(() => {
		onStatus();
		document.getElementById("iTelegramId")?.focus();
	}, []);
	const onRequest = useCallback(async () => {
		await setLoading(true);
		const _comment = comment.trim();
		const result = await Axios.post("/api/player/vipRequest", { telegramId, comment: _comment.length > 0 ? _comment : undefined, birthday });
		const o = await onResult(result, "unexpected_error");
		if (o?.ok) {
			Toast.success(lang.translate("request_sent"));
			setInfo(o);
		}
		setLoading(false);
	}, [telegramId, comment, birthday]);
	return (
		<Container className="affiliation VipRequest">
			<>
				<header>
					<h1>{lang.translate("vip_request")}</h1>
				</header>
				{loading && <Loader />}
				<main>
					<h3>{lang.translate("overall_data")}</h3>

					<div className="block row">
						<b>{lang.translate("total_wagered")}&nbsp;$:</b>
						<Input
							id="iWagerUsd"
							className="m10_b"
							name="iWagerUsd"
							placeholder={`${lang.translate("wager")}`}
							value={info.wagerUsd.toFixed(2)}
							validation="amount"
						/>
					</div>
					<div className="block row">
						<b>{lang.translate("wager_required")}&nbsp;$:</b>
						<Input
							id="iWagerReqUsd"
							className="m10_b"
							name="iWagerReqUsd"
							placeholder={`${lang.translate("wager")}`}
							value={info.wagerUsdMin.toFixed(2)}
							validation="amount"
						/>
					</div>
					{info.status === "none" && (
						<>
							<div className="block">
								<div>
									<b>{lang.translate("telegram")}:</b>
									<b className="imp">*</b>
								</div>
								<Input
									id="iTelegramId"
									className="m10_b"
									name="iTelegramId"
									placeholder={`${lang.translate("telegram")}`}
									value={telegramId}
									onChange={(e) => setTelegramId(e.target.value)}
									validation="salt"
								/>
							</div>
							<div className="block">
								<div>
									<b>{lang.translate("birthday")}:</b>
									<b className="imp">*</b>
								</div>
								<input
									type="date"
									id="iBirthday"
									name="iBirthday"
									value={birthday}
									min="1900-01-01"
									max={dateFormat(Date.now(), "yyyy-mm-dd")}
									onChange={(v) => [console.log(v.target.value), setBirthday(v.target.value)]}
									onClick={() => document.getElementById("iBirthday").showPicker()}
									data-date-inline-picker="true"
								/>
							</div>

							<div className="block">
								<div>
									<b>{lang.translate("comment")}:</b>
								</div>
								<Input
									id="iComment"
									className="m10_b"
									name="iComment"
									type="textarea"
									placeholder={`${lang.translate("comment")}`}
									value={comment}
									onChange={(e) => setComment(e.target.value)}
								/>
							</div>
						</>
					)}

					<div className="block btn">
						{info.status === "none" ? (
							<button onClick={(e) => [e.stopPropagation(), onRequest()]} disabled={disabled} className="btnDefault warn">
								{lang.translate("submit")}
							</button>
						) : (
							<div>
								{/* <b>{lang.translate("status")}:&nbsp;</b> */}
								<b style={{ fontSize: "22px", color: info.status === "pending" ? "gold" : "lime" }}>{lang.translate(info.status)}</b>
							</div>
						)}
					</div>
				</main>
			</>
		</Container>
	);
});
VipRequest.displayName = "VipRequest";
export default VipRequest;
