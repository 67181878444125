import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Axios from "../../classes/axios";
import { onResult, isMobile, gameNameNormalized } from "../../classes/utills";
import { useSelector } from "react-redux";
import { Icon } from "react-icons-kit";
import { starO } from "react-icons-kit/fa/starO";
import { star } from "react-icons-kit/fa/star";
import lang from "../../classes/lang";
// import { go } from "../../classes/routesLazy";
import Toast from "../../classes/toast";
import { useRef } from "react";
import { Link } from "react-router-dom";
import { storeSet } from "../..";
const SlotegratorGame = (props) => {
	SlotegratorGame.propTypes = {
		game: PropTypes.object,
		sticker: PropTypes.object, //{backgroundColor: "red", color: "white",}
	};
	const imgWrapperRef = useRef();
	const { game } = props;
	const excluded = !(game.rtp >= 90 && game.rtp <= 97) || game.excludedFromWager;
	const exclStr = excluded ? (gameNameNormalized(game.provider).includes("flappy") ? "incl.1/3" : "excl.") : "incl.";
	const inDepBonus = useSelector((store) => (store.playerConfig || {}).depBonus?.active === true);
	const slotsFav = useSelector((store) => (store.playerConfig || {}).slotsFav || []);
	// eslint-disable-next-line no-unused-vars
	const me = useSelector((store) => store.player.uid);
	const chatOpen = useSelector((store) => !!store.chat?.open);
	const langRoute = useSelector((store) => store.langRoute);
	// const onGameClick = async (game, demo) => {
	// 	let _name = gameNameNormalized(game.name);
	// 	let _provider = gameNameNormalized(game.provider);
	// 	const path = `${demo ? "demo" : "slot"}/${_name.toLowerCase()}/${_provider.toLowerCase()}`;
	// 	go(path);
	// };
	const gamePath = useMemo(() => {
		const demo = me === -1 ? true : false;
		let _name = gameNameNormalized(game.name);
		let _provider = gameNameNormalized(game.provider);
		let path = `/${langRoute}/${demo ? "demo" : "slot"}/${_name.toLowerCase()}/${_provider.toLowerCase()}`;
		if (game.provider === "flappybet") {
			path = !demo ? `/${langRoute}/originals/${_name}/${game.id}/real` : `/${langRoute}/originals/${_name}/${game.id}/demo`;
		}
		return path;
	}, [me, game, langRoute]);

	const isFavourite = (game) => {
		return slotsFav.indexOf(game.uuid) !== -1;
	};
	const onFavouriteSet = async (game) => {
		const f = isFavourite(game);
		let result = await Axios.post("/api/slotegrator/favouriteSet", { gid: game.uuid, v: !f });
		onResult(result, "unexpected_error", () => {
			Toast.success(lang.translate(!f ? "game_added_to_favourites" : "game_removed_from_favourites"));
		});
	};
	const url = game.image?.replace("upload/v", "upload/f_auto,q_auto/v");
	return (
		// <div onClick={(e) => [e.stopPropagation(), onGameClick(game, me === -1 ? true : false)]}
		<Link
			to={gamePath}
			onClick={() => {
				requestAnimationFrame(() => {
					storeSet({ pathname: { $set: window.location.pathname } });
					window.scrollTo(0, 0);
				});
			}}
		>
			<div
				key={game.name}
				className={`slotGameSlide ${isMobile() ? "mobile" : "desktop"} ${chatOpen ? "chatOn" : "chatOff"}`}
				data-provider={game.provider}
				data-uuid={game.uuid}
				data-type={game.type}
				data-clicksdemo={game.clicksDemo}
				data-clicksmoney={game.clicksMoney}
			>
				{props.sticker && (
					<div className="sticker" style={{ backgroundColor: props.sticker.backgroundColor, color: props.sticker.color }}>
						{props.sticker.caption}
					</div>
				)}
				<div className="bg">
					<div ref={imgWrapperRef} className="imgWrapper">
						<img
							className="loading"
							src={url} //add q_auto
							onLoad={(el) => [(el.target.className = "loaded"), (imgWrapperRef.current.className += " loaded")]}
							alt=""
							title=""
						/>
					</div>
				</div>
				<div className="content">
					<div className="buttons">
						{/* {me >= 0 && (
						<button onClick={(e) => [e.stopPropagation(), onGameClick(game, false)]} className="btnPlay real">
							{lang.translate("play_now")}
						</button>
					)}
					{!["Evolution2", "Vivogaming"].includes(game.provider) && (
						<button onClick={(e) => [e.stopPropagation(), onGameClick(game, true)]} className="btnPlay demo">
							{lang.translate("free_play")}
						</button>
					)} */}
						{/* onClick={(e) => [e.stopPropagation(), onGameClick(game, me === -1 ? true : false)]} */}
						<button className="btnPlaySvg">
							<svg className="play-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63 63">
								<defs></defs>
								<g fill="none" fillRule="evenodd">
									<circle cx="31.3" cy="31.3" r="30.3" fill="#000" fillOpacity=".2" stroke="#E5E7EE" strokeWidth="2"></circle>
									<path fill="#F5F6F7" d="M39.5 34.3l-11.3 7.5a2 2 0 01-3-1.6v-15a2 2 0 013-1.7L39.5 31a2 2 0 010 3.3z"></path>
								</g>
							</svg>
						</button>
					</div>
					<h3>
						{game.provider !== "flappybet" && (
							<button className="btnFav btnIcon" onClick={(e) => [e.preventDefault(), e.stopPropagation(), onFavouriteSet(game)]}>
								<Icon icon={isFavourite(game) ? star : starO} />
							</button>
						)}
						<b>{game.name}</b>
					</h3>
					<div className="providerCap">
						<b>{game.provider}</b>
						{inDepBonus && <b className={exclStr.startsWith("excl") ? "red" : "green"}>{exclStr}</b>}
					</div>
				</div>
			</div>
		</Link>
	);
};
export default SlotegratorGame;
