import React, { forwardRef, useEffect, useRef } from "react";
// eslint-disable-next-line no-unused-vars
import PropTypes from "prop-types";

import Container from "../Container";
import useGames from "./useGames";
import { go } from "../../classes/routesLazy";
import { gameNameNormalized } from "../../classes/utills";
import { useSelector } from "react-redux";
import Loader from "../../components/loader/Loader";

const FlappyBet = forwardRef(() => {
	FlappyBet.propTypes = {};
	const me = useSelector((store) => store.player.uid);
	const initFinished = useSelector((store) => store.initFinished);
	const coinSelected = useSelector((store) => store.coin.selected);
	const flappySessions = useSelector((store) => store.flappySessions);
	const { games, getGames } = useGames({ coinSelected });

	useEffect(() => {
		getGames();
	}, []);

	const onGameClick = async (el) => {
		const isDemo = me === -1 ? true : false;
		go(`originals/${gameNameNormalized(el.Game.Name.toLowerCase())}/${el.GameId}/${isDemo ? "demo" : "real"}`);
	};

	const imgWrapperRef = useRef();

	return (
		<Container className="flappyBet">
			<>
				{initFinished ? (
					<div className="gamelist">
						{games.map((el, i) => {
							return (
								<div onClick={(e) => [e.stopPropagation(), onGameClick(el)]} className="game slotGameSlide" key={i}>
									<div className="counter">
										<img src="/assets/player.svg" alt="" title="" />
										<b>{flappySessions.find((s) => s.GameId === el.GameId)?.N ?? 0}</b>
									</div>
									<div className="bg">
										<div ref={imgWrapperRef} className="imgWrapper">
											<img
												className="loading"
												src={el.Image} //add q_auto
												onLoad={(el) => [(el.target.className = "loaded"), (imgWrapperRef.current.className += " loaded")]}
												alt=""
												title=""
											/>
										</div>
									</div>
									<div className="content">
										<div className="buttons">
											<button onClick={(e) => [e.stopPropagation(), onGameClick(el)]} className="btnPlaySvg">
												<svg className="play-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63 63">
													<defs></defs>
													<g fill="none" fillRule="evenodd">
														<circle cx="31.3" cy="31.3" r="30.3" fill="#000" fillOpacity=".2" stroke="#E5E7EE" strokeWidth="2"></circle>
														<path fill="#F5F6F7" d="M39.5 34.3l-11.3 7.5a2 2 0 01-3-1.6v-15a2 2 0 013-1.7L39.5 31a2 2 0 010 3.3z"></path>
													</g>
												</svg>
											</button>
										</div>
										<h3>
											<b>{el.Game.Name}</b>
										</h3>
										<div className="providerCap">
											<b>FlappyBet</b>
											<b className="green">incl.1/3</b>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				) : (
					<Loader />
				)}
			</>
		</Container>
	);
});

FlappyBet.displayName = "FlappyBet";

export default FlappyBet;
