import React, { PureComponent } from "react";
import PropTypes from "prop-types";
//redux:
import { connect } from "react-redux";
//Components:
import Select from "../select/Select";
import SImpleDropDown from "../simpleDropDown/SimpleDropDown";
import lang from "../../classes/lang";
//other:
import DateFormat from "dateformat";

class Calendar extends PureComponent {
	constructor(props) {
		super(props);
		this.utc = false;
		this.today = new Date();
		const sss = (this.props.value instanceof Date ? this.props.value : new Date()).clone();
		this.selected = sss[this.utc ? "utcCeilByMinutes" : "ceilByMinutes"](this.props.minutesChunk);
		this.state = {
			day: this.utc ? this.selected.getUTCDate() : this.selected.getDate(),
			month: this.utc ? this.selected.getUTCMonth() : this.selected.getMonth(),
			year: this.utc ? this.selected.getUTCFullYear() : this.selected.getFullYear(),
			time: this.selected.toHourMinute(this.props.minutesChunk, this.utc),
			foundTime: null,
		};
		this.prevDaysInMonth = 0;
		this.daysInMonth = 0;
		this.months = [
			{ title: lang.translate("Jan"), value: 0 },
			{ title: lang.translate("feb"), value: 1 },
			{ title: lang.translate("mar"), value: 2 },
			{ title: lang.translate("apr"), value: 3 },
			{ title: lang.translate("may"), value: 4 },
			{ title: lang.translate("jun"), value: 5 },
			{ title: lang.translate("jul"), value: 6 },
			{ title: lang.translate("aug"), value: 7 },
			{ title: lang.translate("sep"), value: 8 },
			{ title: lang.translate("oct"), value: 9 },
			{ title: lang.translate("nov"), value: 10 },
			{ title: lang.translate("dec"), value: 11 },
		];
		this.years = [];
		const y = this.utc ? this.selected.getUTCFullYear() : this.selected.getFullYear();
		for (let x = y - 3; x <= y + 3; x++) this.years.push(x);
		this.calcTimes();
	}
	static get propTypes() {
		return {
			lang: PropTypes.string,
			onChange: PropTypes.func, //returns Date
			value: PropTypes.object, //Date
			minDate: PropTypes.object, //Date
			maxDate: PropTypes.object, //Date
			minutesChunk: PropTypes.number.isRequired,
		};
	}
	componentDidMount = () => {
		this.postSelect({ day: this.state.day, month: this.state.month, year: this.state.year, time: this.state.time, change: true });
	};
	componentDidUpdate = (prevProps) => {
		const valueChanged = this.props.value !== prevProps.value;
		const minutesChunkChanged = this.props.minutesChunk !== prevProps.minutesChunk;
		if (minutesChunkChanged) this.calcTimes();
		if (valueChanged || minutesChunkChanged) {
			this.setState(
				{
					month: this.utc ? this.props.value.getUTCMonth() : this.props.value.getMonth(),
					year: this.utc ? this.props.value.getUTCFullYear() : this.props.value.getFullYear(),
					time: this.props.value.toHourMinute(this.props.minutesChunk, this.utc),
					day: this.utc ? this.props.value.getUTCDate() : this.props.value.getDate(),
				},
				() => {
					this.postSelect({ day: this.state.day, month: this.state.month, year: this.state.year, time: this.state.time, change: false });
				}
			);
		}
	};
	calcTimes = () => {
		this.times = [];
		for (let h = 0; h <= 23; h++) {
			for (let m = 0; m <= 59; m += this.props.minutesChunk) {
				this.times.push(`${h < 10 ? "0" : ""}${h}:${m < 10 ? "0" : ""}${m}`);
			}
		}
	};
	postSelect = ({ day, month, year, time, change = true }) => {
		const _time = typeof time === "undefined" ? this.state.time : time;
		const _day = typeof day === "undefined" ? this.state.day : day;
		const _month = typeof month === "undefined" ? this.state.month : month;
		const _year = typeof year === "undefined" ? this.state.year : year;

		const timeArr = _time.split(":");
		const hours = (timeArr[0].charAt(0) === "0" ? timeArr[0].substring(1) : timeArr[0]) * 1;
		const minutes = (timeArr[1].charAt(0) === "0" ? timeArr[1].substring(1) : timeArr[1]) * 1;
		this.selected = this.utc ? new Date(Date.UTC(_year, _month, _day, hours, minutes)) : new Date(_year, _month, _day, hours, minutes);
		if (this.props.minDate instanceof Date) {
			const d = this.props.minDate.clone();
			const minDate = this.utc ? d.utcCeilByMinutes(this.props.minutesChunk) : d.ceilByMinutes(this.props.minutesChunk);
			if (this.selected.getTime() < minDate.getTime()) this.selected = minDate;
		}
		if (this.props.maxDate instanceof Date) {
			const d = this.props.maxDate.clone();
			const maxDate = this.utc ? d.utcCeilByMinutes(this.props.minutesChunk) : d.ceilByMinutes(this.props.minutesChunk);
			if (this.selected.getTime() > maxDate.getTime()) this.selected = maxDate;
		}
		if (change && typeof this.props.onChange === "function") this.props.onChange(this.selected.clone());
		else this.setState({ r: Math.random() });
	};
	onDaySelect = (day, mode) => {
		if (mode !== 1) return;
		this.postSelect({ day });
	};
	getDaysInMonth = (month, year) => (this.utc ? new Date(Date.UTC(year, month + 1, 0)).getUTCDate() : new Date(year, month + 1, 0).getDate());
	isToday = (day) => {
		if (this.utc) {
			return this.state.year === this.today.getFullYear() && this.state.month === this.today.getMonth() && this.today.getDate() === day;
		} else {
			return this.state.year === this.today.getUTCFullYear() && this.state.month === this.today.getUTCMonth() && this.today.getUTCDate() === day;
		}
	};
	renderWeekNames = () => {
		const renderWeekName = (caption) => {
			return <b>{lang.translate(caption)}</b>;
		};
		const arr = [];
		for (let el of ["mon", "tue", "wed", "thu", "fri", "sat", "sun"]) {
			arr.push(
				<button key={el} className="weekName">
					{renderWeekName(el)}
				</button>
			);
		}
		return arr;
	};
	renderDay = (day, mode) => {
		const modeCl = ["previous", "current", "next"][mode];
		const _today = mode === 1 && this.isToday(day) ? " today" : "";
		const selected = mode === 1 && this.state.day === day ? " selected" : "";
		return (
			<button
				key={`renderDay_${day}_${mode}`}
				onClick={(e) => [e.stopPropagation(), this.onDaySelect(day, mode)]}
				className={`btnDay ${modeCl}${_today}${selected}`}
			>
				{day}
			</button>
		);
	};
	renderDays = (month, year) => {
		const offset = this.utc ? new Date(Date.UTC(year, month, 1)).getUTCDay() : new Date(year, month, 1).getDay();
		this.prevDaysInMonth = this.getDaysInMonth(month - 1, year);
		this.daysInMonth = this.getDaysInMonth(month, year);
		const arr = [];
		arr.push(
			<div key={"renderDays"} className="row">
				{this.renderWeekNames()}
			</div>
		);
		let i = 1 - 7;
		for (let y = 0; y < 7; y++) {
			const row = [];
			for (let x = 0; x < 7; x++) {
				let day = i - offset + 1;
				let mode = -1;
				if (day < 1) {
					mode = 0;
					day = this.prevDaysInMonth + day;
				} else if (day > this.daysInMonth) {
					mode = 2;
					day = day - this.daysInMonth;
				} else {
					mode = 1;
				}
				row.push(this.renderDay(day, mode));
				i++;
			}
			arr.push(
				<div key={`renderDays_${y}`} className={`row row${y}`}>
					{row}
				</div>
			);
		}
		return arr;
	};
	onSelectChange = async (name, item) => {
		console.log(name, item);
		// this.years[name]["sysvalue"] = [item];
		// this.years[name]["value"] = item.value;
		let o;
		if (name === "year" || name === "time") {
			o = { [name]: item.id };
		} else if (name === "month") {
			o = { [name]: item.value * 1 };
		}
		if (o) {
			o.foundTime = null;
			await this.setState(o);
			this.postSelect(o);
		}
	};
	onSearch = async (pattern, _case) => {
		if (!_case) pattern = pattern.toLowerCase();
		const foundTime = await this.times.filter((el) => el.indexOf(pattern) !== -1);
		this.setState({ foundTime });
	};
	render() {
		const value = this.selected ? DateFormat(this.selected, "yyyy-mm-dd HH:MM", this.utc) : "---";
		const header = <b>{value}</b>;
		const content = (
			<div className="calendar">
				<header>
					<Select
						style={{ flex: 1 }}
						caseSearch={false}
						valueHeader={true}
						valueBody={false}
						onSelect={(item) => this.onSelectChange("year", item)}
						multi={false}
						min={1}
						selected={[this.state.year]}
						data={this.years}
						toggleButton={false}
					/>
					<Select
						style={{ flex: 1 }}
						caseSearch={false}
						valueHeader={false}
						valueBody={false}
						onSelect={(item) => this.onSelectChange("month", item)}
						multi={false}
						min={1}
						selected={[this.months[this.state.month]]}
						data={this.months}
						toggleButton={false}
					/>
					<Select
						style={{ flex: 1 }}
						caseSearch={false}
						valueHeader={false}
						valueBody={false}
						onSelect={(item) => this.onSelectChange("time", item)}
						multi={false}
						min={1}
						selected={[this.state.time]}
						data={this.state.foundTime || this.times}
						onSearch={this.onSearch}
						toggleButton={false}
					/>
				</header>
				<main>{this.renderDays(this.state.month, this.state.year)}</main>
			</div>
		);

		return (
			<SImpleDropDown
				render={true}
				className="calendarDropDown"
				headerClassname="calendarHeaderDropDown"
				header={header}
				content={content}
				showArrow={true}
			/>
		);
	}
}
const a = (store) => {
	return {
		lang: store.lang.key,
	};
};

export default connect(a, null, null, { forwardRef: true })(Calendar);
