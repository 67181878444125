import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useEffect } from "react";
//classes:
import Axios from "../../classes/axios";
import { useCallback } from "react";
import { lang as _lang } from "../../classes/lang";

const Doc = (props) => {
	const { showMore, onShowMore } = props;
	const isServer = typeof document === "undefined";
	const [docs, setDocs] = useState(); //must be undefined to make serverside call
	const [ids, setIds] = useState();
	const { serverGetDocs, serverGetPathname, lang, docIds, h1s } = useSelector(
		(state) => {
			return {
				serverGetDocs: state.serverGetDocs,
				serverGetPathname: state.serverGetPathname,
				serverGetH1: state.serverGetH1,
				lang: state.langRoute || "en",
				docIds: state.docIds,
				h1s: state.h1s,
			};
		}
	);
	const pathname = isServer ? serverGetPathname() : window.location.pathname;
	useEffect(() => {
		if (isServer) return;
		const h1 = document.getElementsByTagName("h1")[0];
		let strippedUrl = window.location.pathname.substr(3);
		strippedUrl = strippedUrl.length > 0 ? strippedUrl : "/";
		const txt = h1s ? (h1s[strippedUrl] || h1s[`${strippedUrl.substr(0, strippedUrl.lastIndexOf("/"))}/*`] || {})[lang] : ""; // || h1s["default"][lang];
		if (h1 && txt) h1.innerText = txt;
		else if (h1) h1.remove();
	}, [lang, isServer, h1s]);
	useEffect(() => {
		// console.log("DOC: ids:", ids, pathname);
		Array.isArray(ids) &&
			ids.length > 0 &&
			Axios.post("/api/doc/getAll", { ids }).then((result) => {
				const arr = Array.isArray(result) ? result : [];
				setDocs(arr);
			});
	}, [ids]);
	const extractIds = useCallback(() => {
		// console.log("extractIds: ", docIds)
		let p = pathname.substr(3);
		if (p === "") p = "/";
		const _ids = (docIds && docIds[p]) || null;
		setIds(_ids);
		return _ids;
	}, [pathname, docIds]);
	useEffect(() => {
		extractIds();
		// eslint-disable-next-line
	}, [pathname, docIds, extractIds]);

	//only runs on serverSide:
	if (isServer && docIds !== undefined) {
		if (ids === undefined) {
			// console.log("calling server 1:......", pathname);
			extractIds();
		}
		if (ids !== undefined && docs === undefined) {
			// console.log("calling server 2:......");
			setDocs(serverGetDocs(ids) || []);
		}
	}

	const renderDoc = (doc, i) => {
		if (typeof doc !== "object" || doc === null) return null;
		// if (!doc[lang]) doc[lang] = doc["en"];
		if (!doc[lang]) return null;
		return (
			<div className="docItem" key={`doc_${i}`}>
				{doc[lang].h2 && <h2>{doc[lang].h2}</h2>}
				{doc[lang].h3 && <h3>{doc[lang].h3}</h3>}
				{doc[lang].preview && <p className="txt preview" dangerouslySetInnerHTML={{ __html: doc[lang].preview }} />}
				{doc[lang].body && <p className={`txt body ${showMore ? "on" : "off"}`} dangerouslySetInnerHTML={{ __html: doc[lang].body }} />}
				<div className="showMore">
					<button className={`${showMore ? "up" : "down"}`} onClick={(e) => [e.stopPropagation(), onShowMore()]}>
						<b>{_lang.translate(showMore ? "hide" : "show_more")}</b>
						<div className="arrow"></div>
					</button>
				</div>
			</div>
		);
	};
	return docs ? (
		<section className="docs" data-pathname={pathname}>
			{docs.map((doc, i) => renderDoc(doc, i))}
		</section>
	) : null;
};
Doc.propTypes = {
	onShowMore: PropTypes.func.isRequired,
	showMore: PropTypes.bool.isRequired,
};
export default Doc;
