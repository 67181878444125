/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import config from "../../config";
import PropTypes from "prop-types";
import { stripTrailingSlashes } from "../../classes/utills";

import Axios from "../../classes/axios";

const Turnstile = ({ onPass, onError }) => {
	Turnstile.propTypes = {
		onPass: PropTypes.func,
		onError: PropTypes.func,
	};

	const [verificationStatus, setVerificationStatus] = useState(null); // null, "success", or "failure"

	useEffect(() => {
		if (typeof window === "undefined" || verificationStatus != null) return;
		const script = document.createElement("script");
		script.src = "https://challenges.cloudflare.com/turnstile/v0/api.js";
		script.async = true;
		document.body.appendChild(script);

		script.onload = () => {
			window.turnstile.ready(() => {
				// eslint-disable-next-line no-unused-vars
				const widgetId = window.turnstile.render("#turnstileId", {
					sitekey: config.turnstile.key,
					callback: async (token) => {
						try {
							const response = await Axios.post("/api/turnstile/verify", { token });
							if (response.ok) {
								setVerificationStatus("success");
								if (typeof onPass === "function") onPass();
							} else {
								setVerificationStatus("failure");
								if (typeof onError === "function") onError();
								// window.turnstile.reset(widgetId); // Reset the widget
							}
						} catch (error) {
							console.error("Verification failed:", error);
							setVerificationStatus("failure");
							if (typeof onError === "function") onError();
							// window.turnstile.reset(widgetId); // Reset the widget
						}
					},
					"error-callback": () => {
						console.error("Turnstile encountered an error");
						setVerificationStatus("failure");
						if (typeof onError === "function") onError();
					},
				});
			});
		};

		return () => {
			document.body.removeChild(script);
		};
	}, []);

	return (
		<div>
			{verificationStatus !== "failure" && <div id="turnstileId" className="cf-turnstile" data-sitekey={config.turnstile.key}></div>}
			{verificationStatus === "success" && <p style={{ color: "green" }}>Verification successful!</p>}
			{verificationStatus === "failure" && <p style={{ color: "red" }}>Verification failed. Please try again.</p>}
		</div>
	);
};

export default Turnstile;
