import React, { PureComponent } from "react";
//other:
import PropTypes from "prop-types";
import ResponsiveModal from "react-responsive-modal";
//containers:
import ErrorBoundary from "../ErrorBoundary";
//modals:
import Offline from "../Offline/Offline";
import Prompt from "../prompt/Prompt";
import YesNo from "../prompt/YesNo";
import Fairness from "../games/fairness/Fairness";
import DiceSettings from "../games/dice/DiceSettings";
import SiteSettings from "../siteSettings/SiteSettings";
import DiceRollInfo from "../games/dice/DiceRollInfo";
import BalanceChange from "../balance/BalanceChange";
import ShortcutModal from "../Shortcuts/ShortcutModal";
import ForgotPass from "../ForgotPass/ForgotPass";
import RestoreAccount from "../ForgotPass/RestoreAccount";
import Mute from "../mute/Mute";
import PandoraRoll from "../pandora/PandoraRoll";
import Rain from "../rain/Rain";
import CrashGameInfo from "../games/crash/CrashGameInfo";
import ModalContent from "./ModalContent";
import Registration from "../Registration/Registration";
import CrashAuto from "../games/crash/CrashAuto";
import FortuneWheel from "../fortuneWheel/FortuneWheel";
import JParams from "../fbi/diceMgr/JParams";
import AffToggle from "../affiliation/AffToggle";
import BattleCreate from "../battle/BattleCreate";
import SlotTransaction from "../SlotTransaction/SlotTransaction";
import AffBuy from "../affBuy/AffBuy";
import BonusSpend from "../affBuy/BonusSpend";
import Tip from "../billing/tip/Tip";
import FSBuy from "../billing/fsbuy/FSBuy";
import VipRequest from "../vipRequest/VipRequest";
//redux
import { connect } from "react-redux";
import { storeSet } from "../../index";
import CrashSettings from "../games/crash/CrashSettings";

class Modal extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {};
		this.offline = Offline;
		this.prompt = Prompt;
		this.fairness = Fairness;
		this.dicesettings = DiceSettings;
		this.sitesettings = SiteSettings;
		this.crashsettings = CrashSettings;
		this.yesno = YesNo;
		this.dicerollinfo = DiceRollInfo;
		this.balancechange = BalanceChange;
		this.shortcutmodal = ShortcutModal;
		this.forgotpass = ForgotPass;
		this.restoreaccount = RestoreAccount;
		this.mute = Mute;
		this.pandoraroll = PandoraRoll;
		this.rain = Rain;
		this.crashgameinfo = CrashGameInfo;
		this.modalcontent = ModalContent;
		this.registration = Registration;
		this.crashauto = CrashAuto;
		this.fortunewheel = FortuneWheel;
		this.jparams = JParams;
		this.afftoggle = AffToggle;
		this.closeBtn = null;
		this.battlecreate = BattleCreate;
		this.slottransaction = SlotTransaction;
		this.affbuy = AffBuy;
		this.bonusspend = BonusSpend;
		this.tip = Tip;
		this.fsbuy = FSBuy;
		this["VipRequest".toLowerCase()] = VipRequest;
		//---
	}
	static get propTypes() {
		return {
			modal: PropTypes.object,
		};
	}
	componentDidMount = () => {
		this.container = typeof document === "undefined" ? null : document.getElementById("app");
	};
	onCloseModal = (canClose) => {
		canClose && storeSet({ modal: { $set: null } });
	};
	componentWillUnmount = () => {
		this.closeBtn?.removeEventListener("keypress", this.onKeyDown);
		this.closeBtn = null;
	};
	onKeyDown = (e) => {
		const C = this[this.props.modal.name.toLowerCase()];
		if (typeof C.onKeyDown === "function") {
			C.onKeyDown(e);
		}
	};
	renderModal = () => {
		const modal = Modal.modal;
		const C = this[modal.name.toLowerCase()];
		const canClose = typeof modal.canClose !== "undefined" ? modal.canClose : true;
		requestAnimationFrame(() => {
			const closeBtn = document.querySelector(".modalClose");
			if (closeBtn) {
				this.closeBtn = closeBtn;
				this.closeBtn.removeEventListener("keydown", this.onKeyDown);
				this.closeBtn.addEventListener("keydown", this.onKeyDown);
			}
		});

		return (
			<ResponsiveModal
				classNames={{
					modal: "modalBody",
					closeButton: "btnDefault modalClose",
					overlay: "modalOverlay",
					modalAnimationIn: "customEnterModalAnimation",
					modalAnimationOut: "customLeaveModalAnimation",
				}}
				animationDuration={500}
				onAnimationEnd={() => (Modal.modal = null)}
				container={this.container}
				showCloseIcon={canClose}
				blockScroll={true}
				focusTrapped={false}
				open={this.props.modal !== null}
				onClose={() => this.onCloseModal(canClose)} //modal.name
				center
			>
				<ErrorBoundary>
					<div tabIndex={-1}>
						<C onClose={() => storeSet({ modal: { $set: null } })} {...modal.props} />
					</div>
				</ErrorBoundary>
			</ResponsiveModal>
		);
	};
	render() {
		return Modal.modal ? this.renderModal() : null;
	}
}
const a = (store) => {
	if (store.modal) Modal.modal = store.modal;
	return {
		modal: store.modal,
		// soundOn: store.general.soundOn,
		// homeRef: store.general.homeRef,
		// isMobile: store.general.isMobile
		// init: store.general.init
		// socketConnected: store.general.socketConnected
	};
};
const b = {};
export default connect(a, b)(Modal);
